import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { TreeNode } from 'primeng/api';
import { LegendeGenerator } from './legende-generator';
import { StyleLayer } from '../../models/style-layer.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MapService } from '../../services/map.service';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';


@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent extends BaseComponent implements OnInit {
    public legendeOptions: TreeNode[];
    private legende: LegendeGenerator;
    private listeLayers: StyleLayer[] = this.config.data.listeLayers;

    constructor(
        private mapService: MapService,
        private config: DynamicDialogConfig,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getLegendeInit();
    }

    public getLegendeInit() {
        this.legende = new LegendeGenerator(this.listeLayers);
        this.legendeOptions = this.legende.getLegendeFromLocal();
        this.legendeOptions.forEach(element => {
            element.children!.forEach(elementChildren => {
                this.changeLayerVisibilite(elementChildren);
            });
        });
    }

    public resetLegende() {
        localStorage.removeItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED);
        this.getLegendeInit();
    }

    public toggleLayerVisibilite(nomLayer: TreeNode): void {
        const oldValue = this.map.getLayoutProperty(nomLayer.label!, 'visibility');
        this.changeLayerVisibilite(nomLayer);
        this.changeToLocalStorage(nomLayer.label!, oldValue);
    }

    private get map(): mapboxgl.Map {
        return this.mapService.map;
    }

    private changeLayerVisibilite(nomLayer: TreeNode): void {
        if (nomLayer.data.visibilite) {
            this.map.setLayoutProperty(nomLayer.label!, 'visibility', 'visible');
        } else {
            this.map.setLayoutProperty(nomLayer.label!, 'visibility', 'none');
        }
    }

    private getLocalStorageLayer() {
        return localStorage.getItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED);
    }

    private changeToLocalStorage(layerName: string, oldValue: string) {
        if (this.getLocalStorageLayer() !== null) {
            const layers = JSON.parse(localStorage.getItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED)!);
            if (layers[layerName] === undefined) {
                layers[layerName] = oldValue;
                localStorage.setItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED, JSON.stringify(layers));
            } else {
                delete layers[layerName];
                if (Object.keys(layers).length > 0) {
                    localStorage.setItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED, JSON.stringify(layers));
                } else {
                    localStorage.removeItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED);
                }
            }
        } else {
            const layers: { [name: string]: string } = {};
            layers[layerName] = oldValue;
            localStorage.setItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED, JSON.stringify(layers));
        }
    }
}
