import { forEach, find, findIndex } from 'lodash';
import { TreeNode } from 'primeng/api';
import { LegendeIcon } from 'src/app/map/models/legende-icon.model';
import { StyleLayer } from 'src/app/map/models/style-layer.model';
import { LegendeStyleLayer } from 'src/app/map/models/legende-style-layer.model';
import { LegendeSymbol } from 'src/app/map/models/legende-symbol';
import { LayerType } from '../../models/layer-type.enum';
import { hasOwnProperty } from 'fast-json-patch/module/helpers';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';
export class LegendeGenerator {
    public layerLegendeOption: LegendeStyleLayer;
    private legendeParam: TreeNode[] = [];

    constructor(public style: StyleLayer[]) {
        this.legendeInit(style);
    }

    private getlegendeLayer(stylelayer: StyleLayer): LegendeStyleLayer {
        switch (stylelayer.value.type) {
            case LayerType.FILL:
                const rect: LegendeSymbol = {
                    layout: {
                        'visibility': stylelayer.value.layout?.['visibility'],
                    },
                    paint: {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        'fill-color': stylelayer.value.paint['fill-color'],
                        'fill-outline-color': stylelayer.value.paint['fill-outline-color']
                        /* eslint-enable @typescript-eslint/naming-convention */
                    },
                    visibilite: this.setvisibilite(stylelayer.value.layout?.['visibility']),
                };
                this.layerLegendeOption = { layername: stylelayer.value.id, options: rect, type: 'rect' };
                break;
            case LayerType.CIRCLE:
                const circle: LegendeSymbol = {
                    layout: {
                        visibility: stylelayer.value.layout?.visibility,
                    },
                    paint: {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        'circle-color': stylelayer.value.paint['circle-color'],
                        'circle-radius': stylelayer.value.paint['circle-radius'],
                        'circle-stroke-color': stylelayer.value.paint['circle-stroke-color'],
                        'circle-stroke-width': stylelayer.value.paint['circle-stroke-width'],
                        /* eslint-enable @typescript-eslint/naming-convention */
                    },
                    visibilite: this.setvisibilite(stylelayer.value.layout?.['visibility']),
                };
                this.layerLegendeOption = { layername: stylelayer.value.id, options: circle, type: 'circle' };
                break;
            case LayerType.SYMBOL:
                if (stylelayer.urlimage) {
                    const img: LegendeIcon = {
                        src: stylelayer.urlimage!,
                        alt: stylelayer.value.id,
                        cssText: `height:20px;width:20px`,
                        visibilite: this.setvisibilite(stylelayer.value.layout?.['visibility']),
                    };
                    this.layerLegendeOption = { layername: stylelayer.value.id, options: img, type: 'symbol' };
                    break;
                }
                break;
            // case Type.line:
            //     const line: SymboleLegende = {
            //         layout: {
            //             visibility: stylelayer.value.layout?.['visibility'],
            //             'line-join': stylelayer.value.layout?.['line-join'],
            //             'line-cap': stylelayer.value.layout?.['line-cap']
            //         },
            //         paint: {
            //             'line-color': stylelayer.value.paint['line-color'],
            //             'line-dasharray': stylelayer.value.paint['line-dasharray'],
            //             'line-width': stylelayer.value.paint['line-width']
            //         },
            //         visibilite: this.setvisibilite(stylelayer.value.layout?.['visibility']),
            //         };
            //     this.layerLegendeOption = { layername: stylelayer.value.id, options: line, type: 'line' };
            //     break;
        }
        return this.layerLegendeOption;
    }

    private legendeInit(style: StyleLayer[]) {
        forEach(style, (layerGroupe, indexGroupe) => {
            const nomLayer = find(this.legendeParam, (groupe) => groupe.label === layerGroupe.nomGroupe);
            const indexLayer = findIndex(this.legendeParam, nomLayer);
            const stylelayer: LegendeStyleLayer = this.getlegendeLayer(layerGroupe);
            if (nomLayer) {
                this.legendeParam[indexLayer].children?.push({ key: String(indexGroupe), label: layerGroupe.value.id, data: stylelayer.options, type: stylelayer.type });
            } else {
                this.legendeParam.push({
                    key: String(indexGroupe), label: layerGroupe.nomGroupe, children: [{
                        key: String(indexGroupe),
                        label: layerGroupe.value.id, data: stylelayer.options, type: stylelayer.type
                    }]
                });
            }
        });
    }

    public getLegende(): TreeNode<any>[] {
        return this.legendeParam;
    }

    private setvisibilite(visibilite: string | undefined): boolean {
        if (visibilite === 'visible') {
            return true;
        } else {
            return false;
        }
    }

    private getLocalStorageLayer() {
        return localStorage.getItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED);
    }

    public getLegendeFromLocal() {
        const layers = this.getLocalStorageLayer() ? JSON.parse(localStorage.getItem(LocalStorageIndex.LEGEND_LAYERS_MODIFIED)) : null;
        if (!layers || Object.keys(layers).length === 0) {
            return this.legendeParam;
        }

        return this.legendeParam.map((layerGroup: TreeNode) => ({
            ...layerGroup,
            children: layerGroup.children?.map((layer: TreeNode) =>
                hasOwnProperty(layers, layer.label) ? {
                    ...layer,
                    data: {
                        ...layer.data,
                        layout: {
                            ...layer.data.layout,
                            visibility: layers[layer.label] === 'visible' ? 'none' : 'visible',
                        },
                        visibilite: layers[layer.label] === 'visible' ? false : true,
                    }
                } : layer
            )
        }));
    }
}
